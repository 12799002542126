




import RequestResult from "@/common/models/RequestResult";
import ContentDataContext from "@/dataContexts/ContentDataContext";
import BaseEntryContentView from "@/views/content/BaseEntryContentView.vue";
import { Component } from "vue-property-decorator";
import { Content } from "@sokkuri/common";

@Component
export default class AnimeContentView extends BaseEntryContentView {
    private contentDataContext = new ContentDataContext();

    protected async loadContents(): Promise<RequestResult<Content[]>> {
        return this.contentDataContext.getAnimeContents(this.entryId);
    }
}
