
























import { Component, Vue } from "vue-property-decorator";
import RequestResult from "@/common/models/RequestResult";
import StringUtils from "@/common/utilities/StringUtils";
import { EntryTitle } from "@sokkuri/common";
import AnimeDataContext from "@/dataContexts/AnimeDataContext";
import EntryUtils from "@/common/utilities/EntryUtils";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import { Content,  Constants } from "@sokkuri/common";

@Component({
    components: {
        SpinnerComponent
    }
})
export default class BaseEntryContentView extends Vue {
    private loading = false;
    private animeDataContext: AnimeDataContext = new AnimeDataContext();

    protected entryId: number;
    private entryTitle: EntryTitle = new EntryTitle();
    private streamLinkages: Content[] = [];

    created() {
        this.entryId = +this.$route.params.id;

        if (this.entryId) {
            this.loading = true;

            const getTitles = this.animeDataContext.getTitles(this.entryId);
            getTitles.then(x => {
                if (x.successfully && x.data) {
                    this.entryTitle = EntryUtils.getTitle(x.data);
                }
            });

            const getContents = this.loadContents();
            getContents.then((x: RequestResult<Content[]>) => {
                if (x.successfully && x.data) {
                    this.streamLinkages = x.data.filter(y => StringUtils.equalsIgnoreCase(y.type, Constants.ContentTypes.Streamlinking));
                }
            });

            Promise.all([ getTitles, getContents ]).finally(() => this.loading = false);
        }
    }

    private openExternalLink(content: Content) {
        window.open(content.url, "_blank");
    }

    protected async loadContents(): Promise<any> {}
}
